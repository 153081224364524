import { useRouter } from "next/router";
import Link from "next/link";
import React from "react";
import Locales from './locales/data'

const EmailAsLink = ({ isTitle = true, isAlignCenter = false, isFooter = false }) => {
  const { locale } = useRouter();

  return (
    <div className={`${isAlignCenter ? "text-center" : "text-left"} w-full xl:w-[270px]`}>
      {isTitle && (
        <p className="uppercase opacity-50 tracking-wider pt-4 lg:pt-0">{Locales[locale]}</p>
      )}
      <div className={`inline-flex items-center ${isFooter ? "my-3" : "my-2"}`}>
        <Link href="mailto:info@epr.com.ua">
          <a className="text-xl menu-link">info@epr.com.ua</a>
        </Link>
      </div>
    </div>
  )
};

export default EmailAsLink;